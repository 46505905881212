export const images = [
  { image: "/img/anette2.png" },
  { image: "/img/anette1.png" },
  { image: "/img/anette3.png" },
  { image: "/img/anette4.png" },
  { image: "/img/anette5.png" },
  { image: "/img/anette6.png" },
  { image: "/img/anette7.png" },
  { image: "/img/anette8.png" },
  { image: "/img/anette9.png" },
  { image: "/img/anette10.png" },
  { image: "/img/anette11.png" },
  { image: "/img/anette12.png" },
  { image: "/img/anette13.png" },
  { image: "/img/anette14.png" },
  { image: "/img/anette15.png" },
  { image: "/img/anette16.png" },
  { image: "/img/anette17.png" },
];
