import styled from "styled-components";

export const StyledH3 = styled.h3`
  text-align: center;
  margin: 0 50px 10px 50px;
  a {
    text-decoration: none;
    color: #9c5026;
    :hover {
      color: #ede5d7;
    }
  }
`;
